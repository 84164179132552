// APP JS
import AOS from 'aos';
AOS.init({
  // Global settings:
  disable: 'false',
  // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded',
  // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init',
  // class applied after initialization
  animatedClassName: 'aos-animate',
  // class applied on animation
  useClassNames: false,
  // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false,
  // disables automatic mutations' detections (advanced)
  debounceDelay: 50,
  // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99,
  // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120,
  // offset (in px) from the original trigger point
  delay: 0,
  // values from 0 to 3000, with step 50ms
  duration: 1000,
  // values from 0 to 3000, with step 50ms
  easing: 'ease-out-back',
  // default easing for AOS animations
  once: false,
  // whether animation should happen only once - while scrolling down
  mirror: false,
  // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom' // defines which position of the element regarding to window should trigger the animation
});

(function ($) {
  $(window).load(function () {
    // owl-carousel
    $(".owl-carousel:not(.slider)").owlCarousel({
      items: 1,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // animation
      animateIn: "fadeIn",
      animateOut: "fadeOut",

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: false,
    });

    // slider
    $(".slider").owlCarousel({
      items: 1,
      margin: 15,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 3,
        },
      },
    });

    // form-sent
    setTimeout(() => {
      var locationSearch = "" + document.location.search;
      if (
        (-1 === locationSearch.indexOf("form[sent]") &&
          -1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
        -1 === document.location.hash.indexOf("#form")
      ) {
        return;
      }

      var $headerHeight = $(".header");
      var $message = $(".form-success-message");

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $("html").scrollTop();
      var newScrollTop =
        $message.offset().top - $headerHeight.outerHeight() - 30;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({
        scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
      });
    }, 500);
  });

  $(document).ready(function () {
    // sticky
    require("../../vendor/w3media/framework/assets/js/V2/body-sticky-class");

    // fancybox
    $("a[data-fancybox]").fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close",
      ],
    });

    // scroll-to-content
    $(".js-3w-scroll-to-content").on("click", function (event) {
      event.preventDefault();
      var identifier = $(this).attr("data-target");
      var $element = $(identifier);

      if ($element) {
        $("html, body").animate({ scrollTop: $element.offset().top }, 500);
      }
    });
  });
})(jQuery);

// header
document.addEventListener("DOMContentLoaded", () => {
  let language = document.querySelector(".site-switcher");
  let social = document.querySelector(".header-social");

  let header = document.querySelector(".top .column");

  let newDiv = document.createElement("div");
  newDiv.classList.add("sub");
  newDiv.appendChild(language);
  newDiv.appendChild(social);

  header.appendChild(newDiv);
});



// social posts

let socialSection = document.querySelector("section.social-section");
if (socialSection) {
  let parentDiv = document.createElement("div");
  let targetDiv = document.createElement("div");

  parentDiv.classList.add("container");
  targetDiv.classList.add("elfsight-app-0f16b4c3-39f8-439a-8176-0043178db1ac");

  parentDiv.appendChild(targetDiv);
  socialSection.appendChild(parentDiv);
}
// family section

let clickables = document.querySelectorAll(".family-member-overview");
let solos = document.querySelectorAll(".family-member");
let backBtns = document.querySelectorAll("a.family-back-btn");
let familySection = document.querySelector(".family-section");

backBtns.forEach((backBtn) => {
  backBtn.addEventListener(
    "click",
    (event) => {
      familySection.classList.remove("grow")
      event.preventDefault();
      document.querySelector(".family-caption").classList.remove("hide");
      clickables.forEach((clickable) => {
        clickable.classList.remove("hide");
      });
      solos.forEach((solo) => {
        solo.classList.remove("selected");
      });
    },
    true
  );
});

function hasAlpha(x, y) {
  let hasAlphaValue = false;
  clickables.forEach((imgDiv) => {
    let img = imgDiv.querySelector("picture img");

    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");

    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0, img.width, img.height);

    let pixelData = ctx.getImageData(x, y, 1, 1).data;
    let alpha = pixelData[3];

    if (alpha > 0) {
      hasAlphaValue = imgDiv.id;
    }
  });

  return hasAlphaValue;
}

let mouse = document.createElement("div");
mouse.classList.add("mouse");
familySection.prepend(mouse);

clickables.forEach((image) => {
  image.addEventListener("click", (event) => {
    if (!hasAlpha(event.offsetX, event.offsetY)) {
      return;
    }
    let clickedId = hasAlpha(event.offsetX, event.offsetY);
    solos.forEach((solo) => {
      if (solo.id === clickedId) {
        solo.classList.add("selected");
        familySection.classList.add("grow")
        clickCustomMouse();
        document.querySelector(".family-caption").classList.add("hide");

        clickables.forEach((clickable) => {
          clickable.classList.add("hide");
        });
      }
    });
  });
});

familySection.addEventListener("mousemove", (event) => {
  mouse.classList.remove("hide");
  mouse.style.left = event.clientX + "px";
  mouse.style.top = event.clientY + "px";
});
familySection.addEventListener("mouseleave", () => {
  mouse.classList.add("hide");
});

function clickCustomMouse() {
  mouse.classList.add("clicked");
  setTimeout(() => {
    mouse.classList.remove("clicked");
  }, 350);
}



